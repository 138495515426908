<template>
  <ValidationObserver ref="form" v-slot="{ invalid }" slim>
    <slot
      :invalid="invalid"
      :showErrorMessage="showErrorMessage"
      :isLoading="isLoading"
      :isSubmitted="isSubmitted"
      :submitError="submitError"
      :values="formValues"
      :submit="submit"
      :ctx="ctx">
    </slot>
  </ValidationObserver>
</template>

<script>
import Vue from "vue"
import { ValidationObserver } from 'vee-validate';
import EventBus from "./EventBus"

export default {
  name: "FormComponent",
  components:{
    ValidationObserver
  },
  props:{
    ajax:{
      type: Boolean,
      default: false
    },
    callback:{
      type: Function
    },
    reset:{
      type: Boolean,
      default: false
    },
    ctx:{
        type: Object,
        default(){ return {} }
    },
    conversion:{
      type: Object,
      default(){ return {} }
    }
  },
  mounted(){
    const inputs = [...this.$el.querySelectorAll("input,textarea")]
    inputs.forEach((el) => {
      const val = el.attributes['data-default-value']
      if(val) this.formValues[el.name] = val.value
   })
  },
  watch:{
    reset(){
      this.isSubmitted = false
      this.showErrorMessage = false
      this.isLoading = false
    }
  },
  data() {
    return {
      showErrorMessage: false,
      isLoading: false,
      isSubmitted: false,
      submitError: false,
      formValues: {}
    }
  },
  methods: {
    ajaxSubmit(form){

      let headers = {
         'x-requested-with': 'XMLHttpRequest'
      }
      let body = new FormData(form)

      const url = form.action
      return fetch(url, {
          method: 'POST',
          body,
          headers
        })
    },
    submit(event) {
      const form = event.target
      this.submitError = false
      this.$refs.form.validate().then(success => {
        this.showErrorMessage = !success
        if (success) {
          this.isLoading = true
          if(this.ajax){
            this.ajaxSubmit(form).then(response => {
              response.json().then(({formSubmitSuccess, formSubmitError, errors}) => {
                if(formSubmitSuccess){
                  EventBus.$emit('hx/track/conversion', { ...this.conversion });
                  this.isSubmitted = true
                  this.submitError = false
                  form.reset()
                  if(this.callback){
                    this.callback(this.ctx)
                  }
                }else if(formSubmitError){
                  console.log(errors)
                  this.isLoading = false
                  this.submitError = true
                }
              }).catch((e)=>{
                console.log("error parsing result")
                this.isLoading = false
                this.submitError = true
              })
            }).catch(error => { console.error(error) })
            .finally(()=>{
              this.isLoading = false
            })
          }else{
            form.submit()
          }
        }
      })
    }
  }
}
</script>
